import { defineStore } from 'pinia'
import type { Card } from '~/ts/types/card'
import type { Plan } from '~/ts/types/plan'
import type { Invoice } from '~/ts/types/invoice'
import type { AppTablePagination } from '~/ts/types/app'
import { useUserStore } from '~/stores/user'

type PurchaseMethod = 'card' | 'direct'

export const useBillingStore = defineStore('billingStore', () => {
    const userStore = useUserStore()
    const api = useApi()
    const notify = useNotify()
    const { tc } = useLang()

    const plans = ref<Plan[]>([])
    const cards = ref<Card[]>([])
    const invoices = ref<Invoice[]>([])
    const invoicePagination = ref<AppTablePagination | undefined>()
    const invoicePaginationPending = ref<boolean>(false)
    const purchaseMethod = ref<PurchaseMethod>('card')
    const firstPlanPrice = ref<number>(0)
    const selectedPlan = ref<Plan>()
    const selectedOperatorQuantity = ref<number>(userStore.activeSubscription?.operator_quantity || 1)
    const selectedCardId = ref<number>()
    const activePlan = shallowRef<Plan | undefined>()

    const planDurationLabelsByValue = computed<Record<number, string>>(() => ({
        1: tc('month-pluralized', 1),
        3: tc('month-pluralized', 3),
        12: tc('year-pluralized', 1),
        24: tc('year-pluralized', 2)
    }))

    const fillState = async (): Promise<void> => {
        if (plans.value.length) {
            return
        }

        const [
            { data: planData, error: planError },
            { data: cardData, error: cardError },
            { data: invoiceData, error: invoiceError }
        ] = await Promise.all([
            api.plan.all(),
            api.card.all(),
            api.invoice.all()
        ])

        if (planError.value || cardError.value || invoiceError.value) {
            throw planError.value || cardError.value || invoiceError.value
        }

        firstPlanPrice.value = parseFloat(planData.value.items[0].price)

        plans.value = planData.value.items.map((item) => {
            item.pricePerOperator = firstPlanPrice.value

            return {
                ...item,
                label: planDurationLabelsByValue.value[item.duration]
            }
        })
        cards.value = cardData.value.items
        invoices.value = invoiceData.value.items

        invoicePagination.value = invoiceData.value._meta

        selectedCardId.value = cards.value[0]?.id

        if (userStore.activeSubscription) {
            selectedPlan.value = activePlan.value = plans.value.find(v => v.id === userStore.activeSubscription.plan_id)
        } else {
            selectedPlan.value = plans.value[0]
        }
    }

    const paginateInvoices = async (page: number): Promise<void> => {
        invoicePaginationPending.value = true

        const { data, error } = await api.invoice.all({ page })

        invoicePaginationPending.value = false

        if (error.value) {
            return
        }

        invoices.value = data.value.items

        invoicePagination.value = data.value._meta
    }

    const purchase = async (invoice?: Invoice): Promise<void> => {
        const currentPurchaseMethod: PurchaseMethod = invoice ? 'direct' : purchaseMethod.value

        if (!invoice) {
            const { data, error } = await api.plan.purchase({
                id: selectedPlan.value.id,
                operatorQuantity: selectedOperatorQuantity.value
            })

            if (error.value) {
                throw error.value
            }

            invoice = data.value
        }

        if (currentPurchaseMethod === 'card') {
            const { data, error } = await api.invoice.payWithCard({
                id: invoice.id,
                cardId: selectedCardId.value
            })

            if (error.value) {
                if (error.value.statusCode === 422) {
                    notify.push({
                        type: 'error',
                        text: error.value.data?.map(v => v.message).join('; ')
                    })

                    return
                }

                throw error.value
            }

            await paginateInvoices(invoicePagination.value.currentPage)

            navigateTo({
                name: 'p-pid-billing-invoice-id',
                params: { id: data.value.id }
            })
        } else if (currentPurchaseMethod === 'direct') {
            const { data, error } = await api.invoice.payWithWayForPay({
                id: invoice.id
            })

            if (error.value) {
                throw error.value
            }

            location.href = data.value.url
        }
    }

    return {
        plans,
        cards,
        invoices,
        invoicePagination,
        invoicePaginationPending,
        purchaseMethod,
        firstPlanPrice,
        selectedPlan,
        selectedOperatorQuantity,
        selectedCardId,
        activePlan,
        planDurationLabelsByValue,
        fillState,
        paginateInvoices,
        purchase
    }
})
